import Paper from '@mui/material/Paper';

import { Grid, Pagination } from '@mui/material';
import BasicTable from './BasicTable';

type Props = {
    results?: any;
    count?: any;
    pageChange: any;
    pageChangeCount: number;
    pageNum: any;
    page: number;
};

const ResultBox: React.FC<Props> = ({ results, count, pageChange, pageChangeCount, pageNum, page }) => {
    const resultSize = count as number;
    const pageSize = Math.ceil(resultSize / 25);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        pageNum(value);
        pageChange(pageChangeCount + 1);
    };

    if (results) {
        return (
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <>
                        <div style={{ display: 'block' }} >
                            {/* 検索結果は総件数を表示 */}
                            <p style={{ float: 'right' }} >検索結果: {resultSize}件</p>
                            <Pagination count={pageSize} variant="outlined" shape="rounded" style={{ marginBottom: '15px' }} page={page} onChange={handleChange} />
                        </div>
                        <BasicTable allData={results} />
                    </>
                </Paper>
            </Grid>
        );
    } else {
        return (
            <></>
        )
    }
}

export default ResultBox;
