import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, Grid, Paper, TextField } from "@mui/material";
import { green} from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateValidationError } from '@mui/x-date-pickers/models';
import { Auth } from "aws-amplify";
import axios from "axios";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import saveAs from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import ResultBox from "../components/ResultBox";
import { carSchoolIdState } from "../globalStates/atoms/carSchoolIdAtom";
import { carSchoolNameState } from "../globalStates/atoms/carSchoolNameAtom";
import { dateFromState } from "../globalStates/atoms/dateFromAtom";
import { dateToState } from "../globalStates/atoms/dateToAtom";

const MainContainer: React.FC = () => {
    // 顧客コードの検索用
    const [searchCustomerCode, setCustomerCode] = React.useState<String | null>(null)
    const [recoilCarSchoolIdState, setRecoilCarSchoolIdState] = useRecoilState(carSchoolIdState);
    // 顧客コードのバリデーション表示用
    const inputCustomerCodeRef = useRef(null);
    const [inputCustomerCodeError, setInputCustomerCodeRefError] = useState(false);
    const [inputErrorCustomerCodeMsg, setInputErrorCustomerCodeMsg] = useState(null);
    const handleCustomerCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (inputCustomerCodeRef.current) {
            const ref = inputCustomerCodeRef.current as any;
            if (!ref.validity.valid) {
                setInputCustomerCodeRefError(true);
                setInputErrorCustomerCodeMsg(ref?.validationMessage);
            } else {
                setInputCustomerCodeRefError(false);
                setInputErrorCustomerCodeMsg(null);
            }
        }
        setCustomerCode(event.target.value);
    };

    // 企業・団体名の検索用
    const [searchCompany, setCompany] = React.useState<String>("")
    const [recoilCarSchoolName, setRecoilCarSchoolName] = useRecoilState(carSchoolNameState);
    // 企業・団体名のバリデーション表示用
    const inputCarSchoolRef = useRef(null);
    const [inputCarSchoolError, setInputCarSchoolRefError] = useState(false);
    const [inputErrorCarSchoolNameMsg, setInputErrorCarSchoolNameMsg] = useState<String| null>(null);
    const handleCarSchoolNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if((event.target.value).length > 50) {
            setInputCarSchoolRefError(true);
            setInputErrorCarSchoolNameMsg("このテキストは 50 文字以内で指定してください。");
        } else if (inputCarSchoolRef.current) {
            const ref = inputCarSchoolRef.current as any;
            if (!ref.validity.valid) {
                setInputCarSchoolRefError(true);
                setInputErrorCarSchoolNameMsg(ref?.validationMessage);
            } else {
                setInputCarSchoolRefError(false);
                setInputErrorCarSchoolNameMsg(null);
            }
            setCompany(event.target.value);
        }
    };

    // 受検日Fromの検索用
    const [examDateFromValue, setExamDateFrom] = React.useState<Date | null>(null)
    const [minExamDateToValue, setMinExamDateToValue] = React.useState<Date | undefined>(undefined)
    const [recoilDateFrom, setRecoilDateFrom] = useRecoilState(dateFromState);
    // 受検日Fromのバリデーション表示用
    const [inputDateFromError, setInputDateFromRefError] = useState(false);
    const [dateFromError, setDateFromError] = React.useState<DateValidationError | null>(null);
    const inputErrorDateFromMsg = React.useMemo(() => {
        switch (dateFromError) {
            case 'invalidDate': {
                setInputDateFromRefError(true);
                return 'YYYY/MM/DD形式で入力してください。';
            }
            case 'maxDate': {
                setInputDateFromRefError(true);
                return ''
            }
            default: {
                setInputDateFromRefError(false);
                return '';
            }
        }
    }, [dateFromError]);
    const handleExamDateFromChange = (newValue: Date | null) => {
        setExamDateFrom(newValue)
        setMinExamDateToValue(newValue ? newValue : undefined)
    };

    // 受検日Toの検索用
    const [examDateToValue, setExamDateTo] = React.useState<Date | null>(null)
    const [recoilDateTo, setRecoilDateTo] = useRecoilState(dateToState);
    const [maxExamDateFromValue, setMaxExamDateFromValue] = React.useState<Date | undefined>(undefined)
    // 受検日Toのバリデーション表示用
    const [inputDateToError, setInputDateToRefError] = useState(false);
    const [dateToError, setDateToError] = React.useState<DateValidationError | null>(null);
    const inputErrorDateToMsg = React.useMemo(() => {
        switch (dateToError) {
            case 'invalidDate': {
                setInputDateToRefError(true);
                return 'YYYY/MM/DD形式で入力してください。';
            }
            case 'minDate': {
                setInputDateFromRefError(true);
                return ''
            }
            default: {
                setInputDateToRefError(false);
                return '';
            }
        }
    }, [dateToError]);
    const handleExamDateToChange = (newValue: Date | null) => {
        setExamDateTo(newValue)
        setMaxExamDateFromValue(newValue ? newValue : undefined)
    };

    // 出力フォーマット
    const [simplified, setSimplified] = React.useState(true);
    const checkOutputFormat = () => {
        setSimplified(!simplified);
    };

    // 検索とページネーション
    const [searchResults, setSearchResults] = useState(null);
    const [searchResultsNum, setSearchResultsNum] = useState(null);
    const [searchResultsNumZero] = useState(false); //初期表示は活性化したいため
    // 検索とページネーションの待機エフェクト
    const [searchLoading, setSearchLoading] = useState(false);
    const [csvLoading, setCsvLoading] = useState(false);
    const [paginationChangeCount, setPaginationChangeCount] = useState(0);
    const [page, pageNum] = useState(1);
    // API リクエスト状態
    const [apiError, setApiError] = useState<String | null>(null);

    const initSearchExec = async (searchCustomerCode: String | null, searchCompany: String | null, examDateFromValue: Date | null, examDateToValue: Date | null) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const idToken = user.signInUserSession.idToken.jwtToken;
            const headers = { headers: { "Authorization": idToken } };
            if (searchCustomerCode || searchCompany || examDateFromValue || examDateToValue) {
                let URL = "https://okfotxzzb2.execute-api.ap-northeast-1.amazonaws.com/exam-data?";
                let hasCarSchoolId = '';
                let hasCarSchoolName = '';
                let hasDateFrom = '';
                let hasDateTo = '';
                if (searchCustomerCode) hasCarSchoolId = "&car_school_id=" + searchCustomerCode as string
                if (searchCompany) hasCarSchoolName = "&car_school_name=" + searchCompany as string
                if (examDateFromValue) {
                    let dateFrom = format(examDateFromValue as unknown as number, 'yyyy/MM/dd');
                    hasDateFrom = "&examination_from=" + dateFrom as string;
                }
                if (examDateToValue) {
                    let dateTo = format(examDateToValue as unknown as number, 'yyyy/MM/dd');
                    hasDateTo = "&examination_to=" + dateTo as string;
                }
                let limitOffset = "&limit=25&offset=0" as string;
                const searchURL = URL + hasCarSchoolId + hasCarSchoolName + hasDateFrom + hasDateTo + limitOffset as string
                await axios.get(searchURL, headers).then((response) => {
                    setApiError(null);
                    setSearchLoading(false);
                    setSearchResults(response.data.data)
                    setSearchResultsNum(response.data.query_condition_total_count)
                    // 検索時にページネーションの値をリセットする
                    setPaginationChangeCount(0);
                    pageNum(1);
                    // 0件のとき、CSVダウンロードボタンを非活性化する
                    // if(response.data.query_condition_total_count === 0) {
                    //     setSearchResultsNumZero(true)
                    // }else{
                    //     setSearchResultsNumZero(false)
                    // }
                });
            } else {
                const searchURL = "https://okfotxzzb2.execute-api.ap-northeast-1.amazonaws.com/exam-data?&limit=25&offset=0";
                await axios.get(searchURL, headers).then((response) => {
                    setApiError(null);
                    setSearchLoading(false);
                    setSearchResults(response.data.data)
                    setSearchResultsNum(response.data.query_condition_total_count)
                    // 検索時にページネーションの値をリセットする
                    setPaginationChangeCount(0);
                    pageNum(1);
                    // 0件のとき、CSVダウンロードボタンを非活性化する
                    // if(response.data.query_condition_total_count === 0) {
                    //     setSearchResultsNumZero(true)
                    // } else {
                    //     setSearchResultsNumZero(false)
                    // }
                });
            }
        } catch (e:any) {
            setSearchLoading(false);
            console.log(e);
            setApiError(e.message);
        }
    };

    // CSVダウンロード
    const initCSVExec = async (searchCustomerCode: String | null, searchCompany: String | null, examDateFromValue: Date | null, examDateToValue: Date | null, simplified: boolean) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const idToken = user.signInUserSession.idToken.jwtToken;
            const headers = { headers: { "Content-type": "text/plain", "Authorization": idToken } };
            const URL = "https://j26m3erf7wogfgxr7toinoxk2a0mkejs.lambda-url.ap-northeast-1.on.aws/";
            let csvPrams: { [key: string]: any; } = {
                "is_simple": simplified,
            };
            let dateFrom = '';
            let dateTo = '';
            if (searchCustomerCode) csvPrams["car_school_id"] = searchCustomerCode as string;
            if (searchCompany) csvPrams["car_school_name"] = searchCompany as string;
            if (examDateFromValue) {
                let dateFromFormat = format(examDateFromValue as unknown as number, 'yyyy/MM/dd');
                dateFrom = dateFromFormat as string;
                csvPrams["examination_from"] = dateFrom;
            }
            if (examDateToValue) {
                let dateToFormat = format(examDateToValue as unknown as number, 'yyyy/MM/dd');
                dateTo = dateToFormat as string;
                csvPrams["examination_to"] = dateTo;
            }
            await axios.post(URL, csvPrams, headers
            ).then(async (response) => {
                const responseURL = response.data.csv_file;
                await axios.get(responseURL, { responseType: "blob" }).then((res) => {
                    setApiError(null);
                    setCsvLoading(false);
                    let fileName = format(new Date(), 'yyyyMMddHHmmss');
                    const blob = new Blob([res.data], { type: "text/csv" });
                    saveAs(blob, fileName);
                });
            });
        } catch (e:any) {
            console.log(e);
            setCsvLoading(false);
            setApiError(e.message);
        }
    };

    const initPageNationSearchExec = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const idToken = user.signInUserSession.idToken.jwtToken;
            const headers = { headers: { "Authorization": idToken } };

            if (recoilCarSchoolIdState || recoilCarSchoolName || recoilDateFrom || recoilDateTo) {
                let URL = "https://okfotxzzb2.execute-api.ap-northeast-1.amazonaws.com/exam-data?";
                let hasCarSchoolId = '';
                let hasCarSchoolName = '';
                let hasDateFrom = '';
                let hasDateTo = '';
                if (recoilCarSchoolIdState) hasCarSchoolId = "&car_school_id=" + recoilCarSchoolIdState as string;
                if (recoilCarSchoolName) hasCarSchoolName = "&car_school_name=" + recoilCarSchoolName as string;
                if (recoilDateFrom) {
                    let dateFrom = format(recoilDateFrom as unknown as number, 'yyyy/MM/dd');
                    hasDateFrom = "&examination_from=" + dateFrom as string;
                }
                if (recoilDateTo) {
                    let dateTo = format(recoilDateTo as unknown as number, 'yyyy/MM/dd');
                    hasDateTo = "&examination_to=" + dateTo as string;
                }
                let limit = "&limit=25" as string;
                let offsetNum = (page - 1) * 25 as number;
                let offset = "&offset=" as string;
                const searchURL = URL + hasCarSchoolId + hasCarSchoolName + hasDateFrom + hasDateTo + limit + offset + offsetNum as string;
                await axios.get(searchURL, headers).then((response) => {
                    setApiError(null);
                    setSearchResults(response.data.data);
                    setSearchResultsNum(response.data.query_condition_total_count);
                });
            } else {
                let URL = "https://okfotxzzb2.execute-api.ap-northeast-1.amazonaws.com/exam-data?&limit=25";
                let offsetNum = (page - 1) * 25 as number;
                let offset = "&offset=" as string;
                const searchURL = URL + offset + offsetNum as string;
                await axios.get(searchURL, headers).then((response) => {
                    setApiError(null);
                    setSearchResults(response.data.data);
                    setSearchResultsNum(response.data.query_condition_total_count);
                });
            }
        } catch (e:any) {
            console.log(e);
            setApiError(e.message);
        }
    };

    const csvDownloadExecute = () => {
        setCsvLoading(true);
        searchExecute();
        initCSVExec(
            searchCustomerCode,
            searchCompany.trim(),
            examDateFromValue,
            examDateToValue,
            simplified
        );
    };

    const searchExecute = () => {
        const trimedCompany = searchCompany.trim()  // TrimしてAPI実行する
        setSearchLoading(true);
        setRecoilCarSchoolIdState(searchCustomerCode);
        setRecoilCarSchoolName(trimedCompany);
        setRecoilDateFrom(examDateFromValue);
        setRecoilDateTo(examDateToValue);
        initSearchExec(
            searchCustomerCode,
            trimedCompany,
            examDateFromValue,
            examDateToValue,
        );
        setCompany(trimedCompany) //trimした値を画面に反映させる
    };

    // ページネーション変更時に実行
    useEffect(() => {
        //　初回遷移時と通常検索時に無限ループしないための処理
        if (paginationChangeCount !== 0) initPageNationSearchExec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationChangeCount]);

    return (
        <Grid container spacing={3}>
            {/* 検索ボックス SearchBox */}
            <Grid item xs={12} md={12} lg={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '90%',
                    }}
                >
                    <>
                        <h2>検索条件</h2>
                        <Divider sx={{ my: 1 }} />
                        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={3}>
                                <p>顧客コード</p>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                    <TextField
                                        error={inputCustomerCodeError}
                                        inputProps={{minLength:10, maxLength:10, pattern: "^[A-Za-z0-9]*$" }}
                                        inputRef={inputCustomerCodeRef}
                                        id="outlined-controlled"
                                        label="顧客コード"
                                        variant="outlined"
                                        helperText={inputErrorCustomerCodeMsg}
                                        onChange={handleCustomerCodeChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={3}>
                                <p>企業・団体名</p>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                    <TextField
                                        error={inputCarSchoolError}
                                        inputProps={{pattern: "[ぁ-んァ-ヶｦ-ﾟ一-龠０-９ 　ａ-ｚＡ-Ｚa-zA-Z0-9（）＆’，－．・]+" }}
                                        inputRef={inputCarSchoolRef}
                                        id="outlined-controlled"
                                        label="企業・団体名"
                                        variant="outlined"
                                        helperText={inputErrorCarSchoolNameMsg}
                                        onChange={handleCarSchoolNameChange}
                                        value={searchCompany} // trimした値を載せたいため
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={3}>
                                    <p>受検日</p>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                        <DatePicker
                                            label="受検日From"
                                            onError={(newError) => setDateFromError(newError)}
                                            slotProps={{
                                                textField: {
                                                    helperText: inputErrorDateFromMsg,
                                                },
                                            }}
                                            value={examDateFromValue}
                                            onChange={handleExamDateFromChange}
                                            maxDate={maxExamDateFromValue}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <p>〜</p>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                        <DatePicker
                                            label="受検日To"
                                            onError={(newError) => setDateToError(newError)}
                                            slotProps={{
                                                textField: {
                                                    helperText: inputErrorDateToMsg,
                                                },
                                            }}
                                            value={examDateToValue}
                                            onChange={handleExamDateToChange}
                                            minDate={minExamDateToValue}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                        {apiError ?
                            <>
                                <div style={{color:'red',  marginTop: '50px', fontWeight: 'bolder'}}><p style={{textAlign: 'center'}}> {apiError} </p></div>
                             </> :
                              <>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={3}>
                                <p>出力フォーマット</p>
                            </Grid>
                            <Grid item xs={0}>
                                <Checkbox
                                    checked={simplified}
                                    onChange={checkOutputFormat}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    style={{ marginTop: '5px' }}
                                />
                            </Grid>
                            <Grid item xs={2} style={{ paddingLeft: 'unset' }}>
                                <p style={{ paddingLeft: '5px' }}>簡易版</p>
                            </Grid>
                        </Grid>
                        </>
                        }
                    </>
                </Paper>
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Button variant="contained" disabled={csvLoading || inputCustomerCodeError || inputCarSchoolError || inputDateFromError || inputDateToError || searchResultsNumZero} onClick={csvDownloadExecute}>
                        CSVダウンロード
                    </Button>
                    {csvLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                marginLeft: '-95px',
                                marginBottom: '-8px',
                            }}
                        />
                    )}
                </Box>
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Button variant="contained" style={{ float: 'right', marginTop: '-45px' }} disabled={searchLoading || inputCustomerCodeError || inputCarSchoolError || inputDateFromError || inputDateToError} onClick={searchExecute}>
                        検索
                    </Button>
                    {searchLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                marginTop: '-40px',
                                marginRight: '20px',
                                float: 'right',
                            }}
                        />
                    )}
                </Box>
            </Grid>
            {/* 検索結果ボックス */}
            <ResultBox results={searchResults} count={searchResultsNum} pageChange={setPaginationChangeCount} pageChangeCount={paginationChangeCount} pageNum={pageNum} page={page} />
        </Grid>
    );
}

export default MainContainer;
