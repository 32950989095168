import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

type Props = {
    allData: object;
};

const BasicTable: React.FC<Props> = ({ allData }) => {
    const dataLists = Object.values(allData);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>顧客コード</TableCell>
                        <TableCell>企業・団体名</TableCell>
                        <TableCell>部署名</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>氏名</TableCell>
                        <TableCell>性別</TableCell>
                        <TableCell>生年月日</TableCell>
                        <TableCell>受検日</TableCell>
                        <TableCell>PDFファイル名</TableCell>
                        <TableCell>PDF開封パスワード</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataLists.map((listItem) => {
                        return (
                            <TableRow
                                key={listItem.identifier}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {listItem.car_school_id}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {listItem.car_school_name}
                                </TableCell>
                                <TableCell component="th" scope="row">{listItem.affiliation}</TableCell>
                                <TableCell>{listItem.student_no}</TableCell>
                                <TableCell>{listItem.name}</TableCell>
                                <TableCell>{listItem.sex}</TableCell>
                                <TableCell>{listItem.date_of_birth}</TableCell>
                                <TableCell>{listItem.date_of_completion_of_examination}</TableCell>
                                <TableCell>{listItem.pdf_filename}</TableCell>
                                <TableCell>{listItem.pdf_password}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BasicTable;
